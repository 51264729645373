<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model.trim="nombre"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombre),
                    rules.maxLength(nombre, 15)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="sssCodigo"
                label="Código SSS"
                dense
                outlined
                :rules="[rules.maxLength(sssCodigo, 2)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="codigoInterno"
                label="Código interno"
                dense
                outlined
                onkeypress="return (event.charCode >= 46 && event.charCode <= 57)"
                :rules="
                  rules.positiveNumber.concat([
                    rules.maxNumber(codigoInterno, 32767)
                  ])
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="codigoMedPre"
                label="Código para medicina prepaga"
                dense
                outlined
                onkeypress="return (event.charCode >= 46 && event.charCode <= 57)"
                :rules="
                  rules.positiveNumber.concat([
                    rules.maxNumber(codigoMedPre, 32767)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="codigoAfip"
                label="Código AFIP"
                dense
                outlined
                :rules="[rules.maxLength(codigoAfip, 2)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="codigoAdesfa"
                label="Código ADESFA"
                dense
                outlined
                :rules="[rules.maxLength(codigoAdesfa, 10)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <ConfirmDialog
        :text="textConfirmDialog"
        :openConfirm.sync="openConfirmDialog"
        @update:openConfirm="cancelar"
        @onConfirm="save(saveRegistro)"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditTipoDocumento",
  components: { ConfirmDialog },
  props: {
    tipoDocumentoObject: {
      type: Object,
      default: null
    },
    configTiposDocumentos: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDIT_TIPO_DOC,
    rules: rules,
    nombre: null,
    sssCodigo: null,
    codigoInterno: null,
    codigoMedPre: null,
    codigoAfip: null,
    codigoAdesfa: null,
    id: null,
    openConfirmDialog: false,
    textConfirmDialog: "",
    saveRegistro: null
  }),
  created() {
    if (this.tipoDocumentoObject) {
      this.setTipoDocumento();
      this.id = this.tipoDocumentoObject.tdId;
    } else {
      this.newTipoDocumento();
    }
  },
  methods: {
    ...mapActions({
      postTipoDocumento: "configAfiliaciones/postTipoDocumento",
      setAlert: "user/setAlert"
    }),
    async newTipoDocumento() {
      this.formEditTitle = "Nuevo tipo de documento";
    },
    setTipoDocumento() {
      this.nombre = this.tipoDocumentoObject.tdNombre;
      this.sssCodigo = this.tipoDocumentoObject.sssCodigo;
      this.codigoInterno = this.tipoDocumentoObject.tdCodInt;
      this.codigoMedPre = this.tipoDocumentoObject.tdCodMedPre;
      this.codigoAfip = this.tipoDocumentoObject.tdCodAfip;
      this.codigoAdesfa = this.tipoDocumentoObject.codAdesfa;
    },
    canSave() {
      this.saveRegistro = {
        tdNombre: this.nombre,
        codAdesfa: this.codigoAdesfa,
        sssCodigo: this.sssCodigo,
        tdCodAfip: this.codigoAfip,
        tdCodInt: this.codigoInterno,
        tdCodMedPre: this.codigoMedPre,
        tdId: this.tipoDocumentoObject?.tdId
      };
      let sameCodeSss;
      if (this.sssCodigo != null) {
        sameCodeSss = this.configTiposDocumentos.find(
          x => x.sssCodigo === this.sssCodigo && x.tdId != this.id
        );
      }
      if (sameCodeSss && this.sssCodigo != "") {
        this.textConfirmDialog = `El código SSS "${this.sssCodigo}" ya se encuentra en "${sameCodeSss.tdNombre}". `;
      }
      let sameCodeInt;
      if (this.codigoInterno != null) {
        sameCodeInt = this.configTiposDocumentos.find(
          x => x.tdCodInt == this.codigoInterno && x.tdId != this.id
        );
      }
      if (sameCodeInt && this.codigoInterno != "") {
        if (this.textConfirmDialog == "")
          this.textConfirmDialog = `El código interno "${this.codigoInterno}" ya se encuentra en "${sameCodeInt.tdNombre}". `;
        else
          this.textConfirmDialog += `El código interno "${this.codigoInterno}" ya se encuentra en "${sameCodeInt.tdNombre}". `;
      }
      let sameCodeMedPre;
      if (this.codigoMedPre != null) {
        sameCodeMedPre = this.configTiposDocumentos.find(
          x => x.tdCodMedPre == this.codigoMedPre && x.tdId != this.id
        );
      }
      if (sameCodeMedPre && this.codigoMedPre != "") {
        if (this.textConfirmDialog == "")
          this.textConfirmDialog = `El código para padrón medicina prepaga "${this.codigoMedPre}" ya se encuentra en "${sameCodeMedPre.tdNombre}". `;
        else
          this.textConfirmDialog += `El código para padrón medicina prepaga "${this.codigoMedPre}" ya se encuentra en "${sameCodeMedPre.tdNombre}". `;
      }
      let sameCodeAfip;
      if (this.codigoAfip != null) {
        sameCodeAfip = this.configTiposDocumentos.find(
          x => x.tdCodAfip === this.codigoAfip && x.tdId != this.id
        );
      }
      if (sameCodeAfip && this.codigoAfip != "") {
        if (this.textConfirmDialog == "")
          this.textConfirmDialog = `El código AFIP "${this.codigoAfip}" ya se encuentra en "${sameCodeAfip.tdNombre}". `;
        else
          this.textConfirmDialog += `El código AFIP "${this.codigoAfip}" ya se encuentra en "${sameCodeAfip.tdNombre}". `;
      }
      let sameCodeAdesfa;
      if (this.codigoAdesfa != null) {
        sameCodeAdesfa = this.configTiposDocumentos.find(
          x => x.codAdesfa == this.codigoAdesfa && x.tdId != this.id
        );
      }

      if (sameCodeAdesfa && this.codigoAdesfa != "") {
        if (this.textConfirmDialog == "")
          this.textConfirmDialog = `El código ADESFA "${this.codigoAdesfa}" ya se encuentra en "${sameCodeAdesfa.tdNombre}". `;
        else
          this.textConfirmDialog += `El código ADESFA "${this.codigoAdesfa}" ya se encuentra en "${sameCodeAdesfa.tdNombre}". `;
      }
      this.textConfirmDialog += "¿Desea confirmar los cambios?";
      if (this.textConfirmDialog === "¿Desea confirmar los cambios?") {
        this.save(this.saveRegistro);
      } else {
        this.openConfirmDialog = true;
      }
    },
    async save(data) {
      const res = await this.postTipoDocumento(data);

      this.openConfirmDialog = false;
      this.textConfirmDialog = "";

      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
      this.closeModal();
      this.saveRegistro = null;
    },
    cancelar() {
      (this.saveRegistro = null), (this.textConfirmDialog = "");
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
