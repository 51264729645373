var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.canSave()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre","dense":"","outlined":"","rules":_vm.rules.required.concat([
                  _vm.rules.requiredTrim(_vm.nombre),
                  _vm.rules.maxLength(_vm.nombre, 15)
                ])},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombre"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Código SSS","dense":"","outlined":"","rules":[_vm.rules.maxLength(_vm.sssCodigo, 2)]},model:{value:(_vm.sssCodigo),callback:function ($$v) {_vm.sssCodigo=$$v},expression:"sssCodigo"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Código interno","dense":"","outlined":"","onkeypress":"return (event.charCode >= 46 && event.charCode <= 57)","rules":_vm.rules.positiveNumber.concat([
                  _vm.rules.maxNumber(_vm.codigoInterno, 32767)
                ])},model:{value:(_vm.codigoInterno),callback:function ($$v) {_vm.codigoInterno=$$v},expression:"codigoInterno"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Código para medicina prepaga","dense":"","outlined":"","onkeypress":"return (event.charCode >= 46 && event.charCode <= 57)","rules":_vm.rules.positiveNumber.concat([
                  _vm.rules.maxNumber(_vm.codigoMedPre, 32767)
                ])},model:{value:(_vm.codigoMedPre),callback:function ($$v) {_vm.codigoMedPre=$$v},expression:"codigoMedPre"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Código AFIP","dense":"","outlined":"","rules":[_vm.rules.maxLength(_vm.codigoAfip, 2)]},model:{value:(_vm.codigoAfip),callback:function ($$v) {_vm.codigoAfip=$$v},expression:"codigoAfip"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Código ADESFA","dense":"","outlined":"","rules":[_vm.rules.maxLength(_vm.codigoAdesfa, 10)]},model:{value:(_vm.codigoAdesfa),callback:function ($$v) {_vm.codigoAdesfa=$$v},expression:"codigoAdesfa"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1),_c('ConfirmDialog',{attrs:{"text":_vm.textConfirmDialog,"openConfirm":_vm.openConfirmDialog},on:{"update:openConfirm":[function($event){_vm.openConfirmDialog=$event},_vm.cancelar],"update:open-confirm":function($event){_vm.openConfirmDialog=$event},"onConfirm":function($event){return _vm.save(_vm.saveRegistro)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }